/* You can add global styles to this file, and also import other style files */
@import "animate.css/animate.min.css";
@import "leaflet/dist/leaflet.css";
@import "swiper/less";
@import "swiper/less/navigation";
@import "swiper/less/pagination";

@font-face {
  font-family: "Poppins-Bold";
  src: url("assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("assets/fonts/poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
}

body,
.mat-typography,
p,
div,
span,
h1,
h2 h3,
h4,
label,
button,
input {
  font-family: "Poppins-Regular", sans-serif !important;
}

.mat-card-title,
.mat-mdc-card-title,
.mat-card-subtitle,
.mat-mdc-card-subtitle {
  font-family: "Poppins-Regular", sans-serif !important;
}

.mat-mdc-header-cell,
.mdc-data-table__header-cell,
.cdk-header-cell {
  font-family: "Poppins-Regular", sans-serif !important;
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.mat-drawer-content {
  overflow: hidden !important;
}

html {
  height: -webkit-fill-available;
}

app-login,
app-register,
app-forgot {
  display: block;
  background-color: #fff;
}

@bigDesktop: ~"(max-width: 1700px)";
@desktop: ~"(max-width: 1499px)";
@tablet: ~"(max-width: 1023px)";
@mobile: ~"(max-width: 767px)";
@smallMobile: ~"(max-width: 420px)";
@bigger: ~"(min-width: 1700px)";

@media @bigDesktop, @desktop, @bigger {
  h2 {
    font-weight: 300;
    font-size: 34px;
    margin: 8px;
  }
}

body {
  margin: 0;
  overscroll-behavior-y: contain;
  background-color: #f3f4f4;
}

.container-home,
.mat-drawer-content {
  background-color: #f3f4f4 !important;
}

app-sidenav {
  display: flex;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 25px;
  background-color: #2d9db2;
}

.mat-snack-bar-container {
  background: #304269 !important;
  color: white !important;

  .mat-simple-snackbar-action {
    color: #a2a62e !important;
  }
}

.scroll-dialog {
  overscroll-behavior-y: contain !important;
}

.no-action {
  touch-action: none;
  pointer-events: none;
}

.loading-no-touch {
  filter: blur(1px);
  touch-action: none;
}

.spacer {
  flex: 1 1 auto;
}

.discount-price {
  color: #a1a62d !important;
}

.discount {
  color: gray !important;
  font-size: 18px;
  text-decoration: line-through;
}

.page {
  height: calc(~"100vh - 5rem");
}

.display-none {
  display: none;
}

h1 {
  font-weight: 300;
  font-size: 40px;
  margin: 10px;
}

h3 {
  font-weight: 300;
  font-size: 30px;
  margin: 5px;
}

h4 {
  margin: 3px;
}

p {
  font-weight: 300;
  font-size: 25px;
  margin: 4px;
  color: #1f1d1d;
  line-height: 30px;
}

a {
  font-size: 20px;
  margin: 4px;
}

.end {
  text-align: end;
}

.center {
  text-align: center !important;
  margin: 0 auto !important;
}

.container {
  display: flex !important;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.column {
  flex-direction: column;
}

.content-center {
  justify-content: center !important;
  text-align: center !important;
}

.content-right {
  justify-content: flex-end !important;
  text-align: right;
}

.content-left {
  justify-content: flex-start;
  text-align: left;
}

.list {
  flex-direction: column;
}

.grid {
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.pointer {
  cursor: pointer;
  outline: none;
}

.m-l-5 {
  margin-left: 5px !important;
}

//width
each(range(1%, 100%, 1), {
  .w-@{index} {
    width: @value !important;
  }
});

each(range(1px, 100px, 1), {
  .p-@{index} {
    padding: @value;
  }
});

each(range(1px, 100px, 1), {
  .mt-@{index} {
    margin-top: @value;
  }
});

.space-top {
  padding-top: 5rem !important;
}

@media (max-width: 1000px) {

  //width
  each(range(1%, 100%, 1), {
    .w-sm-@{index} {
      width: @value;
    }
  });
}

@media (max-width: 960px) {
  .page {
    height: calc(~"100vh - 129px");
  }
}

@media (max-width: 650px) {

  //width
  each(range(1%, 100%, 1), {
    .w-xs-@{index} {
      width: @value !important;
    }
  });

.profile-home {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.safearea {
  padding: 0px !important;
}
}

@media (max-width: 600px) {
  .page {
    height: calc(~"100vh - 113px");
  }
}

.p-8 {
  padding: 8px !important;
}

.m-5 {
  margin: 5px !important;
}

.relative {
  position: relative;
}

.m-0 {
  margin: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 5rem;
}

@media print {
  .not-print {
    display: none !important;
  }
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

@media @tablet {
  .page {
    height: calc(~"100vh - 9rem");
  }

  .space-top {
    padding-top: 9rem !important;
  }
}

@media @mobile {
  h1 {
    font-size: 32px;
  }

  p {
    font-size: 25px;
    line-height: 30px;
  }

  .w-sm-100 {
    width: 100%;
  }

  .w-xs-20 {
    width: 30%;
  }
}

@media @smallMobile {

  //width
  each(range(1%, 100%, 1), {
    .w-xs-@{index} {
      width: @value !important;
    }
  });

.min-w {
  min-width: 35% !important;
}

each(range(1px, 100px, 1), {
  .p-xs-@{index} {
    padding: @value;
  }
});

h1 {
  font-size: 30px;
}

p {
  font-size: 20px;
  line-height: 25px;
}
}

.lds-ellipsis {
  z-index: 100;
  position: fixed;
  top: 40%;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #304269;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex !important;
  justify-content: space-around !important;
}

.space-between {
  justify-content: space-between !important;
}

.top {
  margin-top: 135px;
}

.input-form {
  display: flex;
  flex-flow: column;
  max-width: 700px;
  width: 85%;
  top: -109px;
  position: relative;

  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
    border-radius: 5px;
  }

  h1 {
    color: #5d5b5a;
  }
}

.white {
  color: white;
}

.icon-color-register {
  color: #5d5b5a;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: start;
}

.underline {
  text-decoration: underline;
}

.justify {
  text-align: justify;
}

.absolute {
  position: absolute !important;
}

#google_translate_element {
  font-family: "Poppins-Regular" !important;
}

.goog-te-gadget-simple {
  // background-color: #a2a62e !important;
  // color: white !important;
  font-size: 14px;
  border: none !important;
  padding: 14px 16px !important;
  border-radius: 5px !important;
  line-height: 1rem !important;
  display: block;
  cursor: pointer;
}

.goog-te-menu2 {
  max-width: 100%;
  background: rgb(246, 238, 238);
}

.goog-te-menu-value {
  color: rgba(0, 0, 0, 0.87);

  &:before {
    font-family: "Material Icons";
    content: "\E927";
    margin-right: 9px;
    bottom: 4px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.54) !important;
    vertical-align: -10px;
    position: relative;
  }
}

.goog-te-menu-value span:nth-child(5) {
  display: none;
}

.goog-te-menu-value span:nth-child(3) {
  border: none !important;
  font-family: "Material Icons";

  &:after {
    font-family: "Material Icons";
    content: "\E5C5";
    font-size: 14px;
    vertical-align: -6px;
  }
}

.goog-te-gadget-icon {
  display: none;
}

// ============ HIDE TOP BAR ============
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-te-gadget-simple .goog-te-menu-value span {
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-weight: 400;
}

.help {
  position: fixed !important;
  bottom: 20px;
  left: 20px;
}

.featureSelected {
  transform: scale(1.01);
  box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.36);
}

.load-more-button {
  height: 365px;
  width: 100%;
}

.carousel {
  width: 100%;
  position: relative;

  h4 {
    color: rgb(46, 45, 45);
    margin-top: 65px;
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 24px;
  }

  .navigation {
    border: 1px solid #a2a62e;
    position: sticky;
    top: 40%;
    background-color: white;
    z-index: 1;

    &.next {
      right: 10px;
      margin-left: 20px;
    }

    &.before {
      left: 10px;
      margin-right: 20px;
    }

    &:hover {
      background: rgb(212, 214, 167);
    }
  }

  .load-more-div {
    height: 375px;
    padding: 0 10px;
    min-width: 200px;

    .load-more {
      position: relative;
      top: 50%;
      background: white;
      border: 1px solid #a2a62e;
      border-radius: 25px;
      color: #304269;
      z-index: 1;
      padding: 7px 10px;
      cursor: pointer;
      height: 47px;
      width: 100px;
      z-index: 2;
      outline: none;

      &:hover {
        background: rgb(212, 214, 167);
      }
    }
  }

  .products-container-h {
    height: auto;
    padding: 20px 0px;
    overflow-y: auto;
    display: flex;
    -webkit-overflow-scrolling: touch;
    transition: all 0.3s ease-in-out;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.no-click {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

.feature-item {
  width: 100%;

  img {
    min-height: 144px;
    width: 100%;
    transition: opacity 200ms ease-in-out;
    aspect-ratio: 1.5 / 1;
    object-fit: cover;
  }

  .img {
    border-radius: 20px;
  }

  -webkit-overflow-scrolling: touch;

  height: 370px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  padding: 10px 16px 10px 16px;
  border-radius: 5px;

  .price-format {
    font-family: "Poppins-Regular";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    height: 25px;
    color: #000000;
    animation: priceRevmove 0.3s 1;
  }

  .priceAdded {
    font-size: 18px;
    animation: priceAdded 0.3s 1;
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.36);
  }
}

.info-text-primary {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins-Regular";
  line-height: 21px;
  margin: 20px 5px 0;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  color: #000000;
}

.info-text-secondary {
  margin: 0 5px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  font-family: "Poppins-Light";
  line-height: 21px;
}

.add-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 15px;
  right: 15px;

  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 33px;
    height: 32px;
    border-radius: 5px;
    background: #009db2;
    color: #fff;
    transition: all 0.3s ease-in-out;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: none;
  }

  h1 {
    font-family: "Poppins-Light";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

@keyframes priceAdded {
  0% {
    font-size: 22px;
  }

  25% {
    font-size: 21px;
  }

  50% {
    font-size: 20px;
  }

  75% {
    font-size: 19px;
  }

  100% {
    font-size: 18px;
  }
}

@keyframes priceRevmove {
  0% {
    font-size: 18px;
  }

  25% {
    font-size: 19px;
  }

  50% {
    font-size: 20px;
  }

  75% {
    font-size: 21px;
  }

  100% {
    font-size: 22px;
  }
}

@keyframes firstAddAnimation {
  0% {
    width: 80%;
  }

  25% {
    width: 60%;
  }

  50% {
    width: 50%;
  }

  75% {
    width: 40%;
  }

  100% {
    width: 38px;
  }
}

@keyframes deleteAnimation {
  0% {
    width: 38px;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 30px;
    padding: 0 5px;
    text-indent: -9999px;
  }

  25% {
    width: 50%;
  }

  50% {
    width: 60%;
  }

  75% {
    width: 70%;
  }

  100% {
    width: 80%;
  }
}

@media (max-width: 650px) {
  .carousel {
    .load-more-div {
      height: 365px;

      .load-more {
        top: 47%;
      }
    }

    .products-container-h {
      padding: 10px;
    }
  }
}

@media (max-width: 410px) {
  .carousel {
    .load-more-div {
      height: 357px;

      .load-more {
        top: 47%;
      }
    }
  }
}

.icon {
  width: 40px;
  height: 40px;
}

.scroll-contain {
  position: fixed !important;
  bottom: 0 !important;
  overscroll-behavior: contain;
  min-width: 270px;
  padding-top: 8rem;
}

/* Estilos para Firefox */
.scroll-contain {
  scrollbar-width: thin;
  scrollbar-color: #ffffff #ffffff;
}

.grid-center {
  display: grid;
  place-content: center;
}

.mat-drawer-container {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.down-space {
  padding-bottom: 1rem;
}

.screen-center {
  display: grid;
  place-items: center;
}

.evenly {
  display: flex !important;
  justify-content: space-evenly !important;
  flex-flow: row wrap;
}

.map-class {
  mat-dialog-container {
    max-height: initial;
  }
}

.leaflet-verticalcenter {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  top: 50%;
  /* possible because the placeholder's parent is the map */
  transform: translateY(-50%);
  /* using the CSS3 Transform technique */
  padding-top: 10px;
}

.leaflet-verticalcenter .leaflet-control {
  margin-bottom: 10px;
}

.share-tico {
  color: #009db2;
}

.mb {
  margin-bottom: 1rem;
}

.user-experience-dialog {
  .mat-dialog-container {
    border: 1px solid #a2a62e;
    border-radius: 1rem !important;
  }
}

.img-loading {
  background: linear-gradient(100deg,
      rgba(162, 166, 47, 0.15),
      rgba(162, 166, 47, 0.3) 50%,
      rgba(162, 166, 47, 0.05) 80%);
  background-size: 200%;
  animation: loading 1.2s ease-in-out infinite alternate;
}

.img-loaded {
  opacity: 1;
  background: transparent;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  -o-transition: all 2s ease;
}

@keyframes loading {
  0% {
    background-position: 70%;
  }

  100% {
    background-position: 30%;
  }

  0% {
    background-position: 70%;
  }
}

mat-progress-bar.green .mat-progress-bar-fill::after {
  background-color: #a2a62e;
}

.photo-background {
  background-size: contain !important;
  overflow: hidden;
}

.background-content {
  margin: 2rem;
  margin-top: 5rem;
  background-color: rgba(200, 207, 65, 0.654); //#c8cf41;
  border-radius: 50px;
  padding: 7px;
  padding-top: 47px;
}

.icon-form {
  width: 200px;
  height: 200px;
  position: relative;
  top: -117px;
}

.icon-form {
  width: 150px;
  height: 150px;
  top: -116px;
}

.no-field-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.wrapper-sign-in {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  height: auto;

  .container-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;

    .img-mobile {
      display: none;
    }

    .pc-login {
      width: 600px;
      height: 600px;
    }
  }

  .container-sign-in {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .container-form {
      flex-direction: column;

      h1 {
        font-family: "Poppins-Regular";
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
      }

      .link {
        cursor: pointer;
        font-family: "Poppins-Regular";
        font-size: 13px;
        font-weight: 400;
        line-height: 19.5px;
        text-align: right;
        margin-bottom: 10px;
      }

      .btn-login {
        cursor: pointer;
        padding: 10px;
        background: #304269;
        border-radius: 5px;
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #ffffff;
        text-align: center;
        border: 1px solid #304269;
        margin: 15px 0px;
      }

      .account {
        margin: 15px 0px;
        font-family: "Poppins-Regular";
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;
        text-align: center;

        span {
          border-bottom: 1px solid black;
          cursor: pointer;
          font-weight: 600 !important;
          transition: all 0.5s ease-in-out;

          &:hover {
            color: #009db2;
            border-bottom: 1px solid #009db2;
          }
        }
      }

      .google {
        cursor: pointer;
        border: 1px solid #656565;
        background-color: #ffffff;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Poppins-Regular";
        font-size: 15px;
        font-weight: 600;
        line-height: 22.5px;

        img {
          margin-right: 5px;
          width: 21px;
          height: 21px;
        }
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .pc-login {
    width: 500px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper-sign-in {
    display: block;
    margin-top: 1.5rem;

    .container-banner {
      .pc-login {
        display: none;
      }

      .img-mobile {
        display: block !important;
        width: 100%;
      }
    }

    .container-sign-in {
      .container-form {
        .account {
          font-size: 14px !important;
        }
      }
    }
  }
}

.top-desktop {
  padding-top: 64px;
}

@media (max-width: 599px) {
  .top-mobile {
    padding-top: 112px;
  }
}

@media (min-width: 600px) {
  .top-mobile {
    padding-top: 128px;
  }
}

// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
@supports (padding-top: constant(safe-area-inset-top)) {
  @media (max-width: 599px) {
    --safe-area-inset-top: constant(safe-area-inset-top);

    .top-mobile {
      padding-top: calc(112px + var(--safe-area-inset-top));
    }
  }

  @media (min-width: 600px) {
    --safe-area-inset-top: constant(safe-area-inset-top);

    .top-mobile {
      padding-top: calc(128px + var(--safe-area-inset-top));
    }
  }
}

// Browsers which fully support CSS Environment variables (iOS 11.2+).
@supports (padding-top: env(safe-area-inset-top)) {
  @media (max-width: 599px) {
    --safe-area-inset-top: env(safe-area-inset-top);

    .top-mobile {
      padding-top: calc(112px + var(--safe-area-inset-top));
    }
  }

  @media (min-width: 600px) {
    --safe-area-inset-top: env(safe-area-inset-top);

    .top-mobile {
      padding-top: calc(128px + var(--safe-area-inset-top));
    }
  }
}

.mat-focused,
.mat-form-field-should-float {
  .mat-form-field-label-wrapper {
    transform: translate(0, 8px);
  }
}

.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;
  border-radius: 5px;
  font-family: "Poppins-Regular";
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  text-align: center;
  background: #009db2 !important;
  color: #ffffff !important;
  border: none;
  width: auto;
  min-width: 30%;
}

.btn-outline {
  cursor: pointer;
  width: auto;
  min-width: 30%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 5px;
  background: #ffffff;
  color: #009db2;
  font-family: "Poppins-Regular";
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  text-align: left;
  border: 1px solid #009db2;
}

.close-icon {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn-invalid {
  opacity: 50% !important;
  background: #656565 !important;
  cursor: not-allowed !important;
}

/*snackbar*/

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: #000000 !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
  padding: 0;
  box-shadow: none !important;
}

.snackbar-error {
  border-left: 6px solid #de0202 !important;
  background-color: #ffd6d6 !important;
  color: #000000 !important;
  border-radius: 5px !important;
  font-weight: bold;
  height: 54px !important;
}

.snackbar-success {
  border-left: 6px solid #2bcc10 !important;
  background-color: #d5ffcf !important;
  color: #000000 !important;
  border-radius: 5px !important;
  font-weight: bold;
  height: 54px !important;
}

.snackbar-warning {
  border-left: 6px solid #dab90e;
  background-color: #fffdd9 !important;
  color: #000000 !important;
  border-radius: 5px !important;
  font-weight: bold;
  height: 54px !important;
}